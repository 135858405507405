<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :key="index"
        @click="clickTab(item)"
      >
        <template #title>
          {{ item.label }}
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { g } from "fe-infrastractures";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";

export default {
  data() {
    return {
      tabindex: 0,
      items: [
        {
          index: 0,
          route: "trainingClass",
          label: g("trainingClasses"),
        },
        {
          index: 1,
          route: "trainingMaterials",
          label: g("trainingMaterials"),
        },
      ],
    };
  },
  methods: {
    clickTab(item) {
      this.$router.push({
        name: item.route,
        query: {
          id: this.$route.query.id,
          isView: this.$route.query.isView,
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
        },
      });
    },
  },
  async created() {
    let arr = [];
    this.items.forEach((element) => {
      if (
        element.index == 0 &&
        this.$has(PW_Worker_Portal_Code.Training.View)
      ) {
        arr.push(element);
      }
      if (
        element.index == 1 &&
        this.$has(PW_Worker_Portal_Code.TrainingMaterials.List)
      ) {
        arr.push(element);
      }
    });
    this.items = arr;
    this.tabindex = this.items.findIndex(
      (item) => item.route === this.$route.name
    );
  },
};
</script>


