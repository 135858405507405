<template>
  <div class="noDataParty">
    <!-- <div class="imgbox">
      <img :src="NoImg" alt="no data" srcset="" v-if="showpic" />
    </div> -->
    <div class="desc">
      <div v-html="desc"></div>
    </div>
  </div>
</template>

<script>
import NoImg from "@/assets/image/noData.png";
export default {
  props: {
    desc: String,
    default: "",
    showpic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      NoImg,
    };
  },
};
</script>
