<template>
  <div class="layoutContent">
    <no-data
     
      v-if="isNoData"
     
      :desc="noDataStr"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    
    />
    <div v-else>
      <home-title
        :description="SettingMsg"
        :showpic="
          getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
        "
       />
      <k-alert-msg ref="lblMsg" />
      <div class="contentParty">
        <training-nav />

        <materials
          v-for="(materItem, index) in materialsList"
          :key="index"
          :group-name="materItem.groupName"
          @refresh="refreshData"
          @show-message="downFail"
          v-model="materItem.materialList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialsPage, isNoClassData } from "../../api/training";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import { getPortalPageSetting } from "@/api/home";
import trainingNav from "./components/training-nav.vue";
import Materials from "./components/materials.vue";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
import NoData from "./components/noData.vue";
export default {
  components: { trainingNav, Materials, HomeTitle, NoData },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      materialsList: [],
      isNoData: false,
      noDataStr: "",
      SettingMsg: "",
    };
  },
  created() {
    this.preData();
  },
  methods: {
    preData() {
      isNoClassData().then((res) => {
        this.isNoData = !res.data.info;
      });
      getPortalPageSetting(pageNameEnum.TrainingMaterialNoData).then((res) => {
        this.noDataStr = res.data?.description;
      });
      getPortalPageSetting(pageNameEnum.TrainingMaterialList).then((res) => {
        this.SettingMsg = res.data?.description;
      });
    },
    initData() {
      getMaterialsPage().then((res) => {
        this.materialsList = res.data ?? [];
      });
    },
    refreshData() {
      this.initData();
    },
    downFail(status, message) {
      this.$refs.lblMsg.message(status, message);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.hand:hover {
  cursor: pointer;
}
</style>
